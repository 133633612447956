import { useEffect, useRef, useState } from "react";
import CustomSwitch from "../CustomSwitch";
import { Arrow, Cancel, Confirm, Delete, Download, Edit, Upload } from "../../assets/svg";

export default function EditorSetting({mask, closeMask, replaceText}) {
    
    const [maskG, setMaskG] = useState(false);
    const [maskR, setMaskR] = useState(false);
    const [pageMask, setPageMask] = useState(false);
    const [showModelSelect, setShowModelSelect] = useState(false);
    const fileInput = useRef(null);
    let [edit, setEdit] = useState();
    let [addObj, setAddObj] = useState({
        source: "",
        target: ""
    })
    let [storageObj, setStorageObj] = useState({
        source: "",
        target: ""
    })
    let [replaceObj, setReplaceObj] = useState({
        target: "",
        replacement: ""
    })
    let [pageObj, setPageObj] = useState({
        header: "",
        footer: ""
    })
    let [form, setForm] = useState({
        pageTopBottom: false,   //  导出页首页尾
        replaceIsOn: false,     //  后置替换开关
        replaceList: [],        //  后置替换表
        glossaryIsOn: false,    //  术语开关
        glossaryList: [],       //  术语表
        pictureIsOn: false,
        service: "",
        model: ""
    });

    // 保存设置
    function saveKey() {
        localStorage.setItem("gpt-glossary-status", form.glossaryIsOn);
        localStorage.setItem("gpt-replace-status", form.replaceIsOn);
        localStorage.setItem("gpt-picture-status", form.pictureIsOn);
        localStorage.setItem("translator-service", form.service);
        localStorage.setItem("translator-model", form.model);
        localStorage.setItem("translator-insert", form.pageTopBottom);
        // closeMask()
    }

    useEffect(() => {
        if (form.service === "OpenAI") {
            setShowModelSelect(true);
        } else {
            setShowModelSelect(false);
        }
    }, [form.service]);

    // 修改添加术语form
    function changeAddInput(value, key) {
        addObj[key] = value;
        setAddObj({...addObj});
    }

    // 添加本地术语
    function addLocalGlossary(list, key) {
        if (!addObj.source && !addObj.target) {
            alert("请先写入数据后，再点击添加！")
            return
        }
        const arr = list;
        arr.push({
            id: `$${arr.length + 1}_`,
            source: addObj.source,
            target: addObj.target
        })
        if (key === "gpt-glossary-list") {
            form.glossaryList = arr;
        }else{
            form.replaceList = arr;
        }
        setForm({...form});
        localStorage.setItem(key, JSON.stringify(key === "gpt-glossary-list" ? form.glossaryList : form.replaceList));
        addObj = {
            source: "",
            target: ""
        };
        setAddObj({...addObj})
    }

    // 修改术语form
    function modifyInput(value, key) {
        storageObj[key] = value;
        setStorageObj({...storageObj});
    }

    // 修改术语
    function modifyStorageGlossary(key) {
        if (key === "glossary") {
            Object.assign(form.glossaryList[edit], {source: storageObj.source, target: storageObj.target});
            localStorage.setItem("gpt-glossary-list", JSON.stringify(form.glossaryList));
        }else{
            Object.assign(form.replaceList[edit], {source: storageObj.source, target: storageObj.target});
            localStorage.setItem("gpt-replace-list", JSON.stringify(form.replaceList));
        }
        setForm({...form});
        setEdit(null);
    }

    // 编辑术语表
    function changeEdit(index, key) {
        if (index !== null) {
            // 初始化storage
            const obj = key === "glossary" ? form.glossaryList[index] : form.replaceList[index];
            storageObj = {
                source: obj.source,
                target: obj.target,
            }
            setStorageObj({...storageObj});
        }
        edit = index;
        setEdit(edit);
    }

    // 删除术语表
    function deleteItem(index, key) {
        if (key === "glossary") {
            form.glossaryList.splice(index, 1);
            localStorage.setItem("gpt-glossary-list", JSON.stringify(form.glossaryList));
        }else{
            form.replaceList.splice(index, 1);
            localStorage.setItem("gpt-replace-list", JSON.stringify(form.replaceList));
        }
        setForm({...form});
    }

    // 导出表
    function exportList(key) {
        const arr = key === "glossary" ? form.glossaryList : form.replaceList;
        if (arr.length === 0) {
            alert("请至少写入一条数据！")
            return
        }
        const str = arr.map(row => `${row.source},${row.target}`).join('\n');
        const csvContent = str;
        const blob = new Blob([csvContent], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement("a");
        a.href = url;
        a.download = "data.csv";
        a.click();
    }

    // 下载示例
    function downloadExample(key) {
        const str = key === "glossary" ? 
        `gas,gas`:`气体,燃料`;
        
        const csvContent = str;
        const blob = new Blob([csvContent], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement("a");
        a.href = url;
        a.download = "example.csv";
        a.click();
    }

    // 导入术语表
    function importList(params) {
        fileInput.current.click();
    }

    // 上传表
    function handleFileChange(event, key) {
        const file = event.target.files[0];
        const regex = /\.csv$/;
        if (!regex.test(file.name)) {
            alert('请上传一个 .csv 文件');
            return;
        }
        const reader = new FileReader();
        reader.onload = function(e) {
            // 在这里处理文件内容
            try {
                const res = e.target.result;
                const lines = res.split('\n');

                const arrayObjects = lines.map(line => {
                    const [source, target] = line.split(',');
                    return { source, target };
                });
                if (Array.isArray(arrayObjects)) {
                    // 写入
                    if (key === "glossary") {
                        form.glossaryList = form.glossaryList.concat(arrayObjects);
                        form.glossaryList.forEach((e, i) => {
                            e.id = `$${i+1}_`
                        })
                        localStorage.setItem("gpt-glossary-list", JSON.stringify(form.glossaryList));
                    }else{
                        form.replaceList = form.replaceList.concat(arrayObjects);
                        form.replaceList.forEach((e, i) => {
                            e.id = `$${i+1}_`
                        })
                        localStorage.setItem("gpt-replace-list", JSON.stringify(form.replaceList));
                    }
                    setForm({...form});
                }else{
                    alert("请导入正确格式的文件！")
                }
            } catch (error) {
                console.log(error);
            }
        };
    
        reader.readAsText(file);
    }

    // 修改key
    function changeForm(value, key) {
        form[key] = value;
        setForm({...form});
        saveKey();
    }

    // 修改查找替换obj
    function changeReplaceObj(key, value) {
        replaceObj[key] = value;
        setReplaceObj({...replaceObj});
    }

    // 修改页首页尾obj
    function changePageObj(key, value) {
        pageObj[key] = value;
        setPageObj({...pageObj});

        // 写入到本地缓存中
        const localKey = "translator-insert-" + key;
        localStorage.setItem(localKey, value);
    }

    function init() {
        // 获取local
        const glossaryIsOn = eval(localStorage.getItem("gpt-glossary-status")) || false;
        const glossaryList = JSON.parse(localStorage.getItem("gpt-glossary-list")) || [];
        const replaceIsOn = eval(localStorage.getItem("gpt-replace-status")) || false;
        const replaceList = JSON.parse(localStorage.getItem("gpt-replace-list")) || [];
        const pictureIsOn = eval(localStorage.getItem("gpt-picture-status")) || false;
        const service = localStorage.getItem("translator-service") || "OpenAI";
        const model = localStorage.getItem("translator-model") || "gpt-4o-mini";
        const insert = localStorage.getItem("translator-insert") || false;
        const insertHeader = localStorage.getItem("translator-insert-header") || "";
        const insertFooter = localStorage.getItem("translator-insert-footer") || "";
        pageObj = {
            header: insertHeader,
            footer: insertFooter
        }

        setShowModelSelect(service === "OpenAI" ? true : false);

        setPageObj({...pageObj});
        form = {
            glossaryIsOn,
            glossaryList,
            replaceIsOn,
            replaceList,
            pictureIsOn,
            service,
            model,
            pageTopBottom: eval(insert),
        }
        setForm({...form});
    }

    useEffect(() => {
        init();
    },[mask])

    return (
        mask &&
        <div className="mask">
            {
                maskG ? 
                // 术语表弹窗
                <div className="glossary-container mask-container">
                    <button className="close" onClick={() => setMaskG(false)}>X</button>
                    <div className="glossary-header">
                        术语表
                    </div>
                    <div className="glossary-content">
                        {/* 添加术语 */}
                        <div className="content-header">
                            {/* 导出导入术语表 */}
                            <div className="btns">
                                <input type="file" ref={fileInput} onChange={(event) => handleFileChange(event, "glossary")} style={{ display: 'none' }} />
                                <button onClick={() => exportList("glossary")}style={{display: "flex", alignItems: "center"}}><div className="icon"><Download /></div>导出表</button>
                                <button onClick={importList} style={{display: "flex", alignItems: "center"}}><div className="icon"><Upload /></div>导入表</button>
                                <button onClick={() => downloadExample("glossary")} style={{display: "flex", alignItems: "center"}}><div className="icon"><Download /></div>示例文件</button>
                            </div>
                            <p className="header-label">添加新术语</p>
                            <div className="inner-item">
                                <div className="label">EN</div>
                                <div className="inner">
                                    <input type="text" placeholder="源语言" value={addObj.source} onChange={(e) => changeAddInput(e.target.value, "source")} />
                                </div>
                            </div>
                            <div className="inner-item">
                                <div className="label">ZH</div>
                                <div className="inner">
                                    <input type="text" placeholder="目标语言" value={addObj.target} onChange={(e) => changeAddInput(e.target.value, "target")} />
                                </div>
                            </div>
                            <div className="btn">
                                <button className="normal-btn primary-btn" onClick={() => addLocalGlossary(form.glossaryList, "gpt-glossary-list")}>添加</button>
                            </div>
                        </div>
                        {/* 术语列表 */}
                        <div className="content-list">
                            <p className="list-label">英文 - 中文</p>
                            <div className="line" />
                            {
                                form.glossaryList.map((item, index) => 
                                    edit === index ?
                                        <div className="list-item" key={item.id}>
                                            <div className="source item-box">
                                                <input type="text" value={storageObj.source} onChange={(e) => modifyInput(e.target.value, "source")} />
                                            </div>
                                            <div className="icon">
                                                <Arrow />
                                            </div>
                                            <div className="target item-box">
                                                <input type="text" value={storageObj.target} onChange={(e) => modifyInput(e.target.value, "target")} />
                                            </div>
                                            <div className="icon btn" onClick={() => modifyStorageGlossary()}>
                                                <Confirm />
                                            </div>
                                            <div className="icon btn" onClick={() => changeEdit(null)}>
                                                <Cancel />
                                            </div>
                                        </div>
                                        :
                                        <div className="list-item" key={item.id}>
                                            <div className="source item-box">
                                                {item.source}
                                            </div>
                                            <div className="icon">
                                                <Arrow />
                                            </div>
                                            <div className="target item-box">
                                                {item.target}
                                            </div>
                                            <div className="icon btn" onClick={() => changeEdit(index, "glossary")} >
                                                <Edit />
                                            </div>
                                            <div className="icon btn" onClick={() => deleteItem(index, "glossary")}>
                                                <Delete />
                                            </div>
                                        </div>    
                                )
                            }
                        </div>
                    </div>
                </div>
                :
                maskR ? 
                // 术语表弹窗
                <div className="glossary-container mask-container">
                    <button className="close" onClick={() => setMaskR(false)}>X</button>
                    <div className="glossary-header">
                        后置替换表
                    </div>
                    <div className="glossary-content">
                        {/* 添加替换词 */}
                        <div className="content-header">
                            {/* 导出导入替换表 */}
                            <div className="btns">
                                <input type="file" ref={fileInput} onChange={(event) => handleFileChange(event, "replace")} style={{ display: 'none' }} />
                                <button onClick={() => exportList("replace")}style={{display: "flex", alignItems: "center"}}><div className="icon"><Download /></div>导出表</button>
                                <button onClick={importList}style={{display: "flex", alignItems: "center"}}><div className="icon"><Upload /></div>导入表</button>
                                <button onClick={() => downloadExample("replace")}style={{display: "flex", alignItems: "center"}}><div className="icon"><Download /></div>示例文件</button>
                            </div>
                            <p className="header-label">添加新词条</p>
                            <div className="inner-item">
                                <div className="label">目标</div>
                                <div className="inner">
                                    <input type="text" placeholder="目标语言" value={addObj.source} onChange={(e) => changeAddInput(e.target.value, "source")} />
                                </div>
                            </div>
                            <div className="inner-item">
                                <div className="label">替换</div>
                                <div className="inner">
                                    <input type="text" placeholder="替换语言" value={addObj.target} onChange={(e) => changeAddInput(e.target.value, "target")} />
                                </div>
                            </div>
                            <div className="btn">
                                <button className="normal-btn primary-btn" onClick={() => addLocalGlossary(form.replaceList, "gpt-replace-list")}>添加</button>
                            </div>
                        </div>
                        {/* 术语列表 */}
                        <div className="content-list">
                            <p className="list-label">目标 - 替换</p>
                            <div className="line" />
                            {
                                form?.replaceList?.map((item, index) => 
                                    edit === index ?
                                        <div className="list-item" key={item.id}>
                                            <div className="source item-box">
                                                <input type="text" value={storageObj.source} onChange={(e) => modifyInput(e.target.value, "source")} />
                                            </div>
                                            <div className="icon">
                                                <Arrow />
                                            </div>
                                            <div className="target item-box">
                                                <input type="text" value={storageObj.target} onChange={(e) => modifyInput(e.target.value, "target")} />
                                            </div>
                                            <div className="icon btn" onClick={() => modifyStorageGlossary()}>
                                                <Confirm />
                                            </div>
                                            <div className="icon btn" onClick={() => changeEdit(null)}>
                                                <Cancel />
                                            </div>
                                        </div>
                                        :
                                        <div className="list-item" key={item.id}>
                                            <div className="source item-box">
                                                {item.source}
                                            </div>
                                            <div className="icon">
                                                <Arrow />
                                            </div>
                                            <div className="target item-box">
                                                {item.target}
                                            </div>
                                            <div className="icon btn" onClick={() => changeEdit(index, "replace")} >
                                                <Edit />
                                            </div>
                                            <div className="icon btn" onClick={() => deleteItem(index, "replace")}>
                                                <Delete />
                                            </div>
                                        </div>    
                                )
                            }
                        </div>
                    </div>
                </div>
                :
                pageMask ?
                <div className="glossary-container mask-container">
                    <button className="close" onClick={() => setPageMask(false)}>X</button>
                    <div className="glossary-header">
                        配置页首页脚
                    </div>
                    <div className="glossary-content">
                        <div className="content-list">
                            <p className="list-label">页首</p>
                            <textarea className="textarea" value={pageObj.header} onChange={(e) => changePageObj("header", e.target.value)}></textarea>
                            <p className="list-label">页尾</p>
                            <textarea className="textarea" value={pageObj.footer} onChange={(e) => changePageObj("footer", e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                :
                <div className="mask-container">
                    <button className="close" onClick={() => closeMask()}>X</button>
                    <div className="mask-content">
                        <h4>常用设置:</h4>
                        {/* 设置术语表 */}
                        <div className="form-item">
                            <div className="form-item-label">
                                <p>术语表:</p>
                            </div>
                            <div className="form-item-inner form-item-switch">
                                <CustomSwitch 
                                    checked={form.glossaryIsOn}
                                    toggleSwitch={() => changeForm(!form.glossaryIsOn, "glossaryIsOn")}
                                />
                                <p onClick={() => setMaskG(true)} >术语表</p>
                            </div>
                        </div>
                        {/* 设置后置替换 */}
                        <div className="form-item">
                            <div className="form-item-label">
                                <p>后置替换:</p>
                            </div>
                            <div className="form-item-inner form-item-switch">
                                <CustomSwitch 
                                    checked={form.replaceIsOn}
                                    toggleSwitch={() => changeForm(!form.replaceIsOn, "replaceIsOn")}
                                />
                                <p onClick={() => setMaskR(true)} >后置替换表</p>
                            </div>
                        </div>
                        {/* 设置图片替换 */}
                        <div className="form-item">
                            <div className="form-item-label">
                                <p>图片替换:</p>
                            </div>
                            <div className="form-item-inner form-item-switch">
                                <CustomSwitch
                                    checked={form.pictureIsOn}
                                    toggleSwitch={() => changeForm(!form.pictureIsOn, "pictureIsOn")}
                                />
                            </div>
                        </div>
                        {/* 设置翻译服务 */}
                        <div className="form-item">
                            <div className="form-item-label">
                                <p>翻译服务:</p>
                            </div>
                            <div className="form-item-inner form-item-switch">
                                <select
                                    onChange={(e) => changeForm(e.target.value, "service")}
                                    defaultValue={form.service}
                                >
                                    <option value="OpenAI">OpenAI</option>
                                    <option value="claude">Claude</option>
                                    <option value="google">Google</option>
                                </select>

                                {showModelSelect && (
                                    <select
                                        onChange={(e) => changeForm(e.target.value, "model")}
                                        defaultValue={form.model}
                                        style={{marginLeft: "20px"}}
                                    >
                                        <option value="gpt-4o-mini">gpt-4o-mini</option>
                                        <option value="gpt-4o">gpt-4o</option>
                                    </select>
                                )}
                            </div>
                        </div>
                        {/* 设置查找与替换 */}
                        {/* <div className="form-item">
                            <div className="form-item-label">
                                <p>查找与替换:</p>
                            </div>
                            <div className="form-item-inner">
                                <input type="text" placeholder="目标文本" onChange={(e) => changeReplaceObj("target", e.target.value)} />
                                <input type="text" placeholder="替换文本" onChange={(e) => changeReplaceObj("replacement", e.target.value)} />
                                <button onClick={() => replaceText(replaceObj)}>替换</button>
                            </div>
                        </div> */}
                        <h4>导出设置:</h4>
                        {/* 设置导出头尾 */}
                        <div className="form-item">
                            <div className="form-item-label">
                                <p>页首页脚:</p>
                            </div>
                            <div className="form-item-inner form-item-switch">
                                <CustomSwitch 
                                    checked={form.pageTopBottom}
                                    toggleSwitch={() => changeForm(!form.pageTopBottom, "pageTopBottom")}
                                />
                                <p onClick={() => setPageMask(true)} >页首脚设置</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mask-footer">
                        <button className="normal-btn" onClick={() => closeMask()}>取消</button>
                        <button className="normal-btn primary-btn" onClick={() => saveKey()}>保存</button>
                    </div> */}
                </div>
            }
        </div>
    )
}